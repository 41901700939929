<template>
  <div class="table">
    <div class="th">
      <span class="label is-bold">Supplementary Documents Request</span>
    </div>
    <span
      v-for="(item,index) in renew_supplementary"
      :key="index"
      class="tr">
      <span class="fs-14 fw-400">{{getOptionName('globalOptions.renew_supplementary',item)}} {{item==100?`: ${form.other_renew_supplementary}`:''}}</span>
      <span class="fs-14"><span class="icon-error">!</span></span>
    </span>
  </div>
</template>

<script>
import MixinOptions from '@/mixins/options'

export default {
  name: 'supplementary-info',
  mixins: [MixinOptions],
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default: () => ({})
    },
  },
  computed:{
    renew_supplementary(){
      let {renew_supplementary} = this.form
      return renew_supplementary||[]
    }
  }
}
</script>

<style scoped lang="scss">
.card {
  background: white;
  border-radius: 5px;
  padding: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12);
  margin-top: 20px;
}
</style>
