<template>
  <div>
    <div class="right ">
      <div class="card">
        <div>
          <div class="flex">
            <span class="label is-bold">Endorsement</span>
          </div>
          <div v-if="!isSave">
            <template v-if="isNext">
              <div>
                <div class="flex flex-aligin">
                  <span
                    v-if="!showOtherInsurer&&productID!==18"
                    class="company-logo">
                    <img
                      :src="getOptionData('insureOptions',company_id).logo"
                      alt="company" />
                  </span>
                  <span v-else>Other Insurer</span>
                  <span
                    v-if="productID==10"
                    class="label"> Motor Insurance-<br>{{getOptionName('carClassOptions',carTypeName)}}</span>
                  <span
                    v-else-if="productID!==10||productID!==18"
                    class="label">{{getOptionName('productNameOptions',productID)}}</span>
                </div>
              </div>
              <div class="flex-sb pd-20">
                <span class="item-name">Total Payable</span>
                <span class="item-val">HKD {{amount_received|formatMoney(2)}}</span>
              </div>
            </template>
            <v-button
              v-if="!isNext"
              class="button-theme button-theme-padding-13"
              @click="handlerCancel"
            > {{isNext?'Back':'Cancel'}}</v-button>
            <v-button
              v-if="!isNext&&!isLock"
              class="button-blue button-theme-padding-13"
              @click="handlerNext"
            >Next</v-button>
            <v-button
              v-if="isNext&&isLock"
              class="button-blue  button-theme-padding-13"
              @click="handlerConfirm">
              <span class="flex-center ">
                <span>Confirm</span>
              </span>
            </v-button>
            <v-button
              v-if="isNext&&!isLock"
              class="button-blue  button-theme-padding-13"
              @click="handlerNext">
              <span class="flex-center ">
                <img
                  src="../../../assets/images/wallet.svg"
                  alt="">
                <span>Set Payment</span>
              </span>
            </v-button>
          </div>
          <div v-else>
            <v-button
              class="button-blue button-theme-padding-13"
              @click="handlerCancel"
            >Cancel</v-button>
            <v-button
              class="button-blue button-theme-padding-13"
              @click="handlerNext"
            >Save</v-button>
          </div>
        </div>

      </div>
    </div>
    <div
      v-if="hasOrderNo"
      id="remark-height"
      ref="remark-height"
      class="remark-body">
      <internet-mark :order-num="orderNo"></internet-mark>
    </div>
  </div>

</template>

<script>
import {mapActions, mapState} from 'vuex'
import MixinOptions from '@/mixins/options'
import MixinRules from '@/mixins/rules'
import MixinFormat from '@/mixins/format'
import InternetMark from '@/views/components/internetMark'

export default {
  name: 'endorsementDialog',
  components:{
    InternetMark,
  },
  mixins: [MixinOptions,MixinRules,MixinFormat],
  props:{
    isNext:{
      type: Boolean,
      default:false
    },
    isLock:{
      type: Boolean,
      default:false
    },
    isSave: {
      type: Boolean,
      default:false
    },
    form:{
      type:Object,
      default:()=>({})
    },
  },
  computed:{
    ...mapState('quotation', ['quoteInformationForm']),
    isEdit(){
      let {is_edit,order_no} = this.quoteInformationForm||{}
      return is_edit==1&&order_no
    },
    orderNo(){
      let {order_no} = this.quoteInformationForm||{}
      return order_no
    },
    hasOrderNo(){
      let {order_no,isDraft} = this.$route.query||{}
      return !!order_no||this.isEdit
    },
    showOtherInsurer(){
      let arr =  [1,2,3,4,6,7,8,9,10,11,12,13]
      return !arr.includes(this.company_id)
    },
    productID(){
      let {product_id} = this.form||{}
      return product_id
    },
    carTypeName(){
      let {class_id} = this.form||{}
      return class_id
    },
    company_id(){
      let {company_id} = this.form||{}
      return company_id
    },
    amount_received(){
      let {premium} = this.form||{}
      let {amount_received} = premium||{}
      return amount_received
    }
  },
  methods:{
    ...mapActions('coverage', ['setDomesticPremium','setHomePremium']),
    ...mapActions('common',['setIsBackPath']),
    handlerCancel(){
      this.setIsBackPath(true)
      this.$router.back()
      this.$emit('handlerCancel')
    },
    handlerNext(){
      this.setDomesticPremium(null)
      this.setHomePremium(null)
      this.$emit('handlerNext')
    },
    handlerConfirm(){
      this.setDomesticPremium(null)
      this.setHomePremium(null)
      this.$emit('handlerConfirm')
    },
  }
}
</script>

<style scoped lang="scss">
.right {
  //margin-top: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  .card {
    background: white;
    border-radius: 5px;
    padding: 15px;
  }
  .button-theme{
    width: 100%;
    margin-bottom: 20px;
    margin-left: 0;
  }
  .button-blue{
    width: 100%;
    margin-bottom: 20px;
    margin-left: 0;
  }
  .is-bold{
    font-weight: bold;
    font-size: 24px;
    color: #000000;
    margin-bottom: 20px;
  }
  .flex-center{
    align-items: center;
    justify-content: center;
    img{
      margin-right: 5px;
    }
  }

  .company-logo {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    width: 78px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;

    img {
      display: block;
      width: 100%;
      // max-height: 100%;
      //height: 42px;
    }
  }

  .label {
    display: block;
    margin: 10px 0;
    font-size: 16px;
    line-height: 19px;
    font-weight: bold;
  }

  .total-box {
    margin: 15px -15px -15px -15px;
    padding: 15px;
    background: #ecf5ff;

    span {
      display: block;
      color: #616266;
      font-size: 14px;
    }

    .amount {
      font-size: 24px;
      color: #409eff;
      font-weight: bold;
    }
  }
  .item-name{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 120%;
    /* or 17px */

    display: flex;
    align-items: center;

    /* Gray/Gray-500 */

    color: #718096;
  }
  .pd-20{
    padding: 20px 0;
  }
  .item-val{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 150%;
    display: flex;
    align-items: center;
    text-align: right;
    color: #000000;
  }

}
.remark-body{
  margin-top: 20px;
}
@media only screen and (min-width: 992px){
  /deep/.remark-body {
    max-height: 600px;
    .card {
      max-height: 600px;
      .remarks-container {
        max-height: 600px;
      }
    }
  }
}

</style>
